import AwareConfig from '@/config/awareConfig';

export default class DemoConfig extends AwareConfig {
  constructor() {
    super();
    this.hasSummary = true;
    this.demo = true;
    this.imos = [
      '9399777',
      '9349514',
      '9525883',
      '9525869',
      '9732319',
      '9708849',
      '9635640',
      '9309409',
      '9295945',
      '9612789',
      '9612997',
      '9349497',
      '9260902',
    ];
    this.features = [
      {
        feature: 'weather',
        weatherProvider: 'windy',
      },
    ];
    this.modeDataFields = [
      {
        mode: 'underway',
        fields: ['duration', 'speed_over_ground_avg', 'consumption_per_day'],
        defaultField: 'cii_attained',
        rankFields: [
          {
            field: 'consumption_per_day',
            textSubfix: 'in daily consumption on this sea passage',
          },
        ],
        insightFields: [
          {
            field: 'consumption_per_day',
            textSubfix: 'is the average consumption on this sea passage',
          },
        ],
        modeDetailsTotalFields: ['duration', 'distance_over_ground', 'speed_over_ground_avg'],
        modeDetailsPerformanceFields: [
          'total_consumption',
          'consumption_per_day',
          'consumption_per_nm',
        ],
        modeDetailsLastMetricsFields: [
          'speed_over_ground_avg',
          'consumption_per_day',
          'consumption_per_nm',
          'cii_attained',
        ],
        modeDetailsSustainabilityFields: ['cii_attained', 'cii_rating'],
      },
      {
        mode: 'port',
        fields: ['duration'],
        defaultField: 'duration',
        rankFields: [],
        insightFields: [
          {
            field: 'duration',
            textSubfix: 'is the average time spent in this port',
          },
        ],
        modeDetailsTotalFields: ['duration'],
        modeDetailsPerformanceFields: [],
        modeDetailsLastMetricsFields: ['duration'],
      },
      {
        mode: 'anchored',
        fields: ['duration'],
        rankFields: [],
        insightFields: [
          {
            field: 'ae_hours_percentage_of_duration',
            textSubfix: "is the vessel's average in this port",
          },
        ],
        modeDetailsTotalFields: ['duration'],
        modeDetailsPerformanceFields: [],
        modeDetailsLastMetricsFields: ['duration'],
      },
    ];
  }
}
